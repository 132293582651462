import * as React from 'react';
import { Form, Row, Col, Input, Modal, Tabs } from 'antd';
import classifiers, { NomenclatureClassifierEntity } from 'lib/api/classifiers';
import { Properties } from 'types';
import { i18n, isLanguage, Languages, languages } from 'lib/utils';

const { TabPane } = Tabs;

const formItemStyle = {
  marginBottom: 0,
  lineHeight: 'initial',
};

interface Props {
  initialValue?: NomenclatureClassifierEntity;
  code: string;
  onSaved: () => void;
}

const CustomOptionListForm: React.FC<Props> = ({ onSaved, code, initialValue }) => {
  const [activeTabKey, setActiveTabKey] = React.useState<Languages>('ro');

  const handleSubmit = async (values: Properties): Promise<void> => {
    try {
      await classifiers.upsertEntity(code, {
        ...(initialValue?.id && { id: initialValue?.id }),
        ...values,
      } as NomenclatureClassifierEntity);
      onSaved();
    } catch (e) {
      const { message = 'Eroare. Formularul nu a putut fi salvat!' } = e;

      Modal.error({ content: message });
    }
  };

  const onChange = (key: string): void => {
    if (isLanguage(key)) setActiveTabKey(key);
  };

  return (
    <Form id="CustomOptionListForm" onFinish={handleSubmit}>
      <Tabs className="custom-tabs" animated={false} activeKey={activeTabKey} onChange={onChange}>
        {languages.map((language) => {
          return (
            <TabPane forceRender tab={i18n.language[language]} key={language}>
              <Form.Item
                label={`Titlu [${language}]`}
                name={['i18n', language, 'title']}
                initialValue={initialValue?.i18n?.[language]?.title}
                rules={[
                  {
                    required: true,
                    message: 'Titlu este necesar',
                  },
                ]}
                style={formItemStyle}
              >
                <Input placeholder={`Introduceți titlul de optiune [${language}]`} />
              </Form.Item>
            </TabPane>
          );
        })}
      </Tabs>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            label="Titlu"
            name="title"
            initialValue={initialValue?.title}
            rules={[
              {
                required: true,
                message: 'Titlu este necesar',
              },
            ]}
            style={formItemStyle}
          >
            <Input placeholder="Introduceți titlul de optiune" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Code"
            name="code"
            initialValue={initialValue?.code}
            rules={[
              {
                required: true,
                message: 'Codul este necesar',
              },
            ]}
            style={formItemStyle}
          >
            <Input placeholder="Introduceți codul de optioune" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CustomOptionListForm;
