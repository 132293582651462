import * as React from 'react';
import { Properties } from 'types';
import { useEffect, useState } from 'react';
import api from 'lib/api';
import { notification, Select } from 'antd';
import { useDebounce } from 'react-use';

interface Props {
  lang: string;
  value?: Properties;
  onChange?: (value: Properties) => void;
}

const LinkSelect = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [pages, setPages] = useState<Properties[]>([]);
  const [state, setState] = useState(props?.value);

  const [, cancel] = useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    350,
    [search],
  );

  useEffect(() => {
    setLoading(true);
    try {
      api.pages
        .all({ name_content_icontains: debouncedSearch, page: 1, page_size: 1000 })
        .then(({ results }) => {
          setPages(results);
          setLoading(false);
        });
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [debouncedSearch]);

  const onChange = (value) => {
    setState(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Select
      value={state}
      loading={loading}
      allowClear
      showSearch
      filterOption={false}
      searchValue={search}
      onSearch={(v) => {
        cancel();
        setSearch(v);
      }}
      optionFilterProp="children"
      autoClearSearchValue
      onChange={(link) => onChange(link)}
    >
      {pages.map((page) => (
        <Select.Option key={page.id} value={page.id}>
          {page.i18n[props.lang].title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LinkSelect;
