import * as React from 'react';
import { Button, Col, Modal, notification, Row, Table } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { Actions } from 'components/Actions';
import { CustomOptionListForm } from 'components/CustomOptionsForm';
import classifiers, { NomenclatureClassifierEntity } from 'lib/api/classifiers';
import { ArrayResult } from 'types';

const CustomOptionList: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const [dataEntity, setDataEntity] = React.useState<ArrayResult<NomenclatureClassifierEntity>>();
  const [selectedItem, setSelectedItem] = React.useState<NomenclatureClassifierEntity>();
  const [openForm, setOpenForm] = React.useState(false);

  const fetchEntity = React.useCallback(async () => {
    try {
      setDataEntity(await classifiers.getEntity(code));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, []);

  React.useEffect(() => {
    fetchEntity();
  }, [fetchEntity]);

  const handleRemove = (id: number) => {
    Modal.confirm({
      title: 'Doriți să ștergeți acestă optiune?',
      onOk: async () => {
        try {
          await classifiers.removeEntity(code, id);
          fetchEntity();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const handleCreate = () => {
    setOpenForm(true);
  };

  const handleEdit = (id: number) => {
    setSelectedItem(dataEntity?.results.find((item) => item.id === id));
    setOpenForm(true);
  };

  const columns: ColumnProps<NomenclatureClassifierEntity>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Titlu',
      dataIndex: 'title',
    },
    {
      title: 'Părinte',
      render: ({ classifier }) => classifier.title,
    },
    {
      title: 'Acțiuni',
      width: 200,
      align: 'right',
      render: (item) => {
        return <Actions id={item.id} onEdit={handleEdit} onRemove={handleRemove} />;
      },
    },
  ];

  const handleOnSave = () => {
    handleCloseForm();
    fetchEntity();
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedItem(undefined);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ marginBottom: 10 }}>
          <Button
            style={{ marginRight: 10 }}
            icon={<ArrowLeftOutlined />}
            size="large"
            onClick={() => history.push('/custom-options')}
          >
            Inapoi
          </Button>

          <Button icon={<PlusOutlined />} type="primary" size="large" onClick={handleCreate}>
            Creează custom optiune list
          </Button>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataEntity ? dataEntity.results : []}
            rowKey="id"
            childrenColumnName=""
            pagination={{
              hideOnSinglePage: true,
              // current: page,
              pageSize: 10,
              // total: data ? data.count : 0,
            }}
            onChange={({ current }, filters: any) => {
              history.push(
                `?${new URLSearchParams({
                  // ...params,
                  ...filters,
                  page: String(current),
                }).toString()}`,
              );
            }}
          />
        </Col>
        {openForm && (
          <Modal
            title="Modal"
            visible={openForm}
            width="30%"
            okButtonProps={{ htmlType: 'submit', form: 'CustomOptionListForm' }}
            onCancel={handleCloseForm}
          >
            <CustomOptionListForm code={code} initialValue={selectedItem} onSaved={handleOnSave} />
          </Modal>
        )}
      </Row>
    </>
  );
};

export default CustomOptionList;
