import React, { useEffect, useState } from 'react';
import { useDebounce, useSetState } from 'react-use';
import { Select, Input, Row, Col, Switch, Upload, Button, message, notification } from 'antd';
import env from '@beam-australia/react-env';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import api from 'lib/api';
import { getBase64 } from 'utils';
import { Properties } from 'types';
import { UploadFile } from 'antd/es/upload/interface';

interface ItemsProps {
  lang: string;
  value?: Properties;
  onChange?: (value: Properties) => void;
}

const REACT_APP_API_BASE_URL = env('API_BASE_URL');

const Items: React.FC<ItemsProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [isIntern, setIsIntern] = useState(
    props?.value?.link !== undefined && !props?.value?.url
      ? !!props?.value?.link
      : props?.value?.url
      ? false
      : true,
  );

  const [, cancel] = useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    350,
    [search],
  );

  const [pages, setPages] = useState<Properties[]>([]);
  const [state, setState] = useSetState(props?.value || {});

  useEffect(() => {
    setLoading(true);
    try {
      api.pages
        .all({ name_content_icontains: debouncedSearch, page: 1, page_size: 1000 })
        .then(({ results }) => {
          setPages(results);
          setLoading(false);
        });
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (props.value) {
      setState(props.value);
      setIsIntern(
        props?.value?.link !== undefined && !props?.value?.url
          ? !!props?.value?.link
          : props?.value?.url
          ? false
          : true,
      );
    }
  }, [props.value]);

  const onChange = (value) => {
    setState((prevState) => ({ ...prevState, ...value }));

    if (props.onChange) {
      props.onChange({ ...state, ...value });
    }
  };

  const handleChange = (info) => {
    switch (info.file.status) {
      case 'uploading':
        setLoading(true);
        break;
      case 'done':
        getBase64(info.file.originFileObj, () => {
          setLoading(false);
        });
        break;
    }
  };

  const handleUpload = async (file) => {
    setLoading(true);

    try {
      const { id: uid, upload, uploaded } = await api.pages.uploadFile(file);

      onChange({
        uid,
        name: upload,
        status: uploaded ? 'done' : 'error',
        url: `${REACT_APP_API_BASE_URL}/v1${upload}`,
      });
    } catch (e) {
      message.error('Unsuccessfull image upload!');
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file) => {
    handleUpload(file);

    return false;
  };

  return (
    <Row wrap gutter={[16, 16]}>
      <Col span={24}>
        <Switch
          checkedChildren="Intern"
          unCheckedChildren="Extern"
          defaultChecked={isIntern}
          onChange={setIsIntern}
        />
      </Col>
      <Col span={24}>
        {isIntern ? (
          <Select
            value={state?.link}
            loading={loading}
            showSearch
            filterOption={false}
            searchValue={search}
            onSearch={(v) => {
              cancel();
              setSearch(v);
            }}
            optionFilterProp="children"
            autoClearSearchValue
            placeholder="Link"
            onChange={(link) => onChange({ link, url: undefined })}
          >
            {pages.map((page) => (
              <Select.Option key={page.id} value={page.id}>
                {page.i18n[props.lang].title}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input
            value={state?.url}
            placeholder="URL"
            onChange={(e) => onChange({ url: e.target.value })}
          />
        )}
      </Col>
      <Col span={24}>
        <Input
          value={state?.text}
          placeholder="Text"
          onChange={(e) => onChange({ text: e.target.value })}
        />
      </Col>
      {!isIntern && (
        <Col span={24}>
          <Upload
            multiple={false}
            fileList={state?.url ? ([state] as UploadFile[]) : []}
            beforeUpload={handleBeforeUpload}
            onChange={handleChange}
          >
            <Button icon={loading ? <LoadingOutlined /> : <PlusOutlined />}>
              Faceți clic pentru a încărca
            </Button>
          </Upload>
        </Col>
      )}
    </Row>
  );
};

export default Items;
