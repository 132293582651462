import api from '.';
import { stringifyUrl } from 'utils/query';
import { EnvApi, Properties } from 'types';
import { ValidationError } from 'errors';

export enum PaymentType {
  ANUITATE = 'ANUITATE',
  STANDARD = 'STANDARD',
}

export enum NomenclatureCreditType {
  WITH_PLEDGE = 'WITH_PLEDGE',
  WITHOUT_PLEDGE = 'WITHOUT_PLEDGE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export enum CommissionType {
  PERCENT = 'PERCENT',
  CURRENCY = 'CURRENCY',
}

export enum CommissionFromType {
  BALANCE = 'BALANCE',
  AMOUNT = 'AMOUNT',
}

export interface NomenclaturesCredits {
  id?: number;
  timestamp?: string;
  edited_timestamp?: string;
  code?: string;
  title?: string;
  description?: string;
  slug?: string;
  data?: Properties;
  payment_types: PaymentType[];
  min_amount: number;
  max_amount: number;
  min_term: number;
  max_term: number;
  is_term_depending: boolean;
  oda_insurance_rates: string[];
  currencies: string[];
}

export interface NomenclatureCreditTypes {
  id?: number;
  timestamp?: string;
  edited_timestamp?: string;
  code?: string;
  title?: string;
  description?: string;
  slug?: string;
  data?: Properties;
  type?: NomenclatureCreditType[];
  is_loial?: boolean;
  has_remittances?: boolean;
  rate: string;
  min_amount: number;
  max_amount: number;
  min_term: number;
  max_term: number;
  commission_from?: CommissionFromType[];
  commission_examination?: string;
  commission_examination_type?: CommissionType[];
  commission_release?: string;
  commission_release_type?: CommissionType[];
  commission_monthly?: string;
  commission_monthly_type?: CommissionType[];
  commission_reimbursement?: string;
  commission_reimbursement_type?: CommissionType[];
  oda_insurance_rate: string[];
  currency: string;
}

export default {
  getCredits: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(
        'nomenclatures/credits',
        { limit: 10, ordering: '-timestamp', ...params },
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  getCreditById: async (id: number) => {
    const { data } = await api.request.get(
      stringifyUrl(`nomenclatures/credits/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );

    return data;
  },

  upsertCredits: async (credit: NomenclaturesCredits) => {
    let error, data;

    try {
      const { id, ...values } = credit;
      if (id) {
        ({ data } = await api.request.patch(
          stringifyUrl(`nomenclatures/credits/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
          values,
          {
            headers: {
              'Accept-Language': 'ro',
            },
          },
        ));
      } else {
        ({ data } = await api.request.post(
          stringifyUrl('nomenclatures/credits', {}, { api: EnvApi.API_MAIN_URL }),
          credit,
          {
            headers: {
              'Accept-Language': 'ro',
            },
          },
        ));
      }
    } catch (e) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;

        const body =
          typeof data === 'string'
            ? JSON.parse(data) // this will fail if string is not valid JSON.
            : data;

        error = new ValidationError(body, data, data.non_field_errors);
      } else {
        error = message;
      }
    }

    return [error, data];
  },

  removeCredit: async (id: number) => {
    await api.request.delete(
      stringifyUrl(`nomenclatures/credits/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );
  },

  getCreditTypes: async (id: number, params = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(
        `nomenclatures/credits/${id}/credit-types`,
        { limit: 10, ordering: '-timestamp', ...params },
        { api: EnvApi.API_MAIN_URL },
      ),
    );

    return data;
  },
  getCreditTypesById: async (creditId: number, id: number) => {
    const { data } = await api.request.get(
      stringifyUrl(
        `nomenclatures/credits/${creditId}/credit-types/${id}`,
        {},
        { api: EnvApi.API_MAIN_URL },
      ),
    );

    return data;
  },

  postCreditTypes: async (id: number, data: NomenclatureCreditTypes) => {
    ({ data } = await api.request.post(
      stringifyUrl(`nomenclatures/credits/${id}/credit-types`, {}, { api: EnvApi.API_MAIN_URL }),
      data,
      {
        headers: {
          'Accept-Language': 'ro',
        },
      },
    ));
  },

  patchCreditTypes: async (creditId: number, id: number, data: NomenclatureCreditTypes) => {
    ({ data } = await api.request.patch(
      stringifyUrl(
        `nomenclatures/credits/${creditId}/credit-types/${id}`,
        {},
        { api: EnvApi.API_MAIN_URL },
      ),
      data,
      {
        headers: {
          'Accept-Language': 'ro',
        },
      },
    ));
  },

  deleteCreditTypes: async (creditId: number, id: number) => {
    await api.request.delete(
      stringifyUrl(
        `nomenclatures/credits/${creditId}/credit-types/${id}`,
        {},
        { api: EnvApi.API_MAIN_URL },
      ),
    );
  },
};
