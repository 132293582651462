import React, { useState, useEffect, useCallback } from 'react';
import { Table, Input, Button, Modal, Divider, notification } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import TranslationModal from './TranslationModal';
import api, { Ii18n /* `Interface i18n` */ } from 'lib/api/i18n';
import { defaultLocale, languages, i18n as util_i18n } from 'lib/utils';

interface TranslationsProps {
  namespace: string;
}

interface filterDropdownProps {
  selectedKeys: string[];
  confirm: () => void;
  clearFilters: () => void;
  setSelectedKeys: (value: string[]) => void;
}

interface EditableElement {
  key: string;
  text: string;
  language: string;
}

const Translations: React.FC<TranslationsProps> = ({ namespace }) => {
  const [i18n, setTranslations] = useState<any>();
  const [isLoading, setLoading] = useState(true);

  const [editable, setEditable] = useState<EditableElement>();
  const [searchText, setSearchText] = useState('');
  const [keyword, setKeyword] = useState('');

  const handleSearch = (selectedKeys: string[], confirm: () => void): void => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void): void => {
    clearFilters();
    setSearchText('');
  };

  const filterDropdown = useCallback(
    ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: filterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          itemRef="any"
          placeholder="Caută după text"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Caută
        </Button>

        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Resetează
        </Button>
      </div>
    ),
    [],
  );

  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: filterDropdown,

    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#00529C' : undefined }} />
    ),

    onFilter: (value: { toLowerCase: () => void }, record: any) =>
      i18n[dataIndex][record].toString().toLowerCase().includes(value.toLowerCase()),

    render: (text: any, record: any) => (
      <>
        <Highlighter
          textToHighlight={i18n[dataIndex][record]}
          searchWords={searchText ? [searchText] : ['']}
          highlightStyle={{ backgroundColor: '#FFDF00', padding: 0 }}
          autoEscape
        />

        <Button
          icon={<EditOutlined />}
          type="dashed"
          shape="circle"
          onClick={() =>
            setEditable({
              language: dataIndex,
              key: record,
              text: i18n[dataIndex][record],
            })
          }
          style={{ border: 'none', background: 'transparent', marginLeft: 10 }}
        />
      </>
    ),
  });

  const parseTranslations = useCallback((data: any) => {
    languages.forEach((language) => {
      data[language] = JSON.parse(data[language]);
    });

    setTranslations(data);
  }, []);

  const fetchTranslations = useCallback(async () => {
    await setLoading(true);

    const { i18n } = await api.getById(namespace);

    try {
      parseTranslations(i18n);
    } catch (e) {
      setTranslations({ ro: {}, ru: {}, en: {} });
      // populate with empty values if namespace is invalid
      Modal.error({ title: 'Namespace broken' });
    }

    await setLoading(false);
  }, [parseTranslations, namespace]);

  useEffect(() => {
    fetchTranslations();
  }, [fetchTranslations]);

  const columns: any = [
    {
      title: 'Keyword',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: string) => {
        return <i>{record}</i>;
      },
      width: 250,
    },

    ...languages.map((lng) => ({
      title: util_i18n.language[lng],
      dataIndex: lng,
      key: lng,
      ...getColumnSearchProps(lng),
    })),
  ];

  const handleEdit = async (newValue: string): Promise<void> => {
    if (editable) {
      try {
        await setLoading(true);

        const { key, language } = editable;
        setEditable(undefined);

        const data = JSON.parse(JSON.stringify(i18n));

        data[language][key] = newValue;

        languages.forEach((language) => (data[language] = JSON.stringify(data[language])));

        const res = await api.update(namespace, data);

        parseTranslations(res.i18n);
        notification.success({ message: `Traducere a fost modificată.` });
      } catch (e) {
        Modal.error({ title: 'Ceva nu a mers bine.', content: e.message });
      }

      await setLoading(false);
    }
  };

  /**
   * DEVELOPEMENT ONLY FUNCTION
   */
  const handleAddKey = async (key: string): Promise<void> => {
    try {
      await setLoading(true);

      const data = JSON.parse(JSON.stringify(i18n));

      languages.forEach((language) => (data[language][key] = `{{ ${language} placeholder }}`));

      languages.forEach((language) => (data[language] = JSON.stringify(data[language])));

      const res: Ii18n = await api.update(namespace, data);

      parseTranslations(res.i18n);
      Modal.success({ title: 'Traducerea a fost modificată cu succes' });
    } catch (e) {
      Modal.error({ title: 'Ceva nu a mers bine', content: e.message });
    }
    await setLoading(false);
  };

  return (
    <>
      <Input.Group style={{ marginBottom: '20px' }}>
        <Input
          placeholder="Adăugați un cuvânt cheie nou"
          value={keyword}
          onChange={(v) => setKeyword(v.target.value.trim())}
          style={{ width: '75%' }}
        />
        <Button
          type="primary"
          onClick={() => {
            handleAddKey(keyword);
            setKeyword('');
          }}
          style={{ width: '25%' }}
        >
          Adăugați
        </Button>
      </Input.Group>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={i18n ? Object.keys(i18n[defaultLocale]) : []}
        pagination={false}
        rowKey="id"
      />
      <TranslationModal
        onSave={handleEdit}
        onCancel={() => setEditable(undefined)}
        text={editable ? editable.text : ''}
        visible={Boolean(editable)}
      />

      {/* Enabling textarea hack via devtools */}
      <div style={{ display: 'none' }}>
        <Divider />

        <Input
          placeholder="Add new keyword"
          onPressEnter={({ target }: any) => {
            const { value } = target;
            handleAddKey(value);
            target.value = '';
          }}
        />

        <Input.TextArea
          style={{ marginTop: 20, height: 500 }}
          value={JSON.stringify(i18n, null, 2)}
          onChange={({ target: { value } }) => {
            try {
              const data = JSON.parse(value);
              setTranslations(data);
            } catch (e) {
              Modal.info({ title: 'incorect' });
            }
          }}
        />
      </div>
    </>
  );
};

export default Translations;
