import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Form, Button, Input, message, Select, Row, Col, Modal, Checkbox } from 'antd';
import credits, { NomenclaturesCredits, PaymentType } from 'lib/api/credits';
import { ValidationError } from 'errors';
import { getSelectOptions } from 'utils';

enum Operation {
  CREATE = 'create',
  EDIT = 'edit',
}

interface ExtraFieldsType {
  currencies?: boolean;
  oda?: boolean;
}

const CreditForm: React.FC = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: Operation }>();
  const [form] = Form.useForm();

  const [loading, setLoading] = React.useState(false);
  const [extraFields, setExtraFields] = React.useState<ExtraFieldsType>();

  React.useEffect(() => {
    if (id && tab === Operation.EDIT) {
      try {
        credits.getCreditById(Number(id)).then((credit) => {
          form.setFieldsValue(credit);
          setExtraFields({
            currencies: credit?.has_several_currencies,
            oda: credit?.has_oda_insurance,
          });
        });
      } catch (e) {
        const { message: err = 'Error. The article could not be saved!' } = e;

        message.error(err);
      }
    }
  }, [id, tab]);

  const handleSubmit = async ({
    payment_types,
    ...values
  }: NomenclaturesCredits): Promise<void> => {
    setLoading(true);
    const payment_type = Array.isArray(payment_types) ? payment_types : [payment_types];

    const [error] = await credits.upsertCredits({
      ...values,
      payment_types: payment_type,
      ...(id && { id: Number(id) }),
    });

    if (!error) {
      if (!id) {
        Modal.success({ title: 'Creditul a fost salvat cu succes' });
      } else {
        Modal.success({ title: 'Creditul a fost editat cu succes' });
      }
      history.push(`/credits`);
    } else {
      if (error instanceof ValidationError) {
        form.setFields(error.fields);
      } else {
        Modal.error({
          title: 'Procesul a eșuat.',
          content: error,
        });
      }
    }
    setLoading(false);
  };

  const onChangeExtraFields = (field: keyof ExtraFieldsType, value: boolean) =>
    setExtraFields((prev) => ({ ...prev, [field]: value }));

  return (
    <Card>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Col span={24} xl={12}>
            <Form.Item
              name="title"
              label="Titlu"
              rules={[{ required: true, message: 'Titlu este necesar!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24} xl={6}>
            <Form.Item
              name="payment_types"
              label="Tipul de plată"
              rules={[
                {
                  required: true,
                  message: 'Tipul de plată este necesar!',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Tipul de plată"
                options={getSelectOptions(Object.values(PaymentType))}
              />
            </Form.Item>
          </Col>

          <Col span={24} xl={6}>
            <Form.Item
              name="min_term"
              label="Numărul minim de luni"
              rules={[
                {
                  required: true,
                  message: 'Numărul minim de luni este necesar!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col span={24} xl={6}>
            <Form.Item
              name="max_term"
              label="Numărul maxim de luni"
              rules={[
                {
                  required: true,
                  message: 'Numărul maxim de luni este necesar!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col span={24} xl={6}>
            <Form.Item
              name="min_amount"
              label="Suma minimala a creditului"
              rules={[
                {
                  required: true,
                  message: 'Suma minimala a creditului este necesară!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col span={24} xl={6}>
            <Form.Item
              name="max_amount"
              label="Suma maximala a creditului"
              rules={[
                {
                  required: true,
                  message: 'Suma maximala a creditului este necesară!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col span={24} xl={6}>
            <Form.Item
              name="is_term_depending"
              label="Dependent de termeni"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>

          <Col span={24} xl={3}>
            <Form.Item
              name="has_several_currencies"
              label="Are mai multe valute"
              valuePropName="checked"
            >
              <Checkbox onChange={(e) => onChangeExtraFields('currencies', e.target.checked)} />
            </Form.Item>
          </Col>

          <Col span={24} xl={3}>
            <Form.Item name="has_oda_insurance" label="Are asigurare ODA" valuePropName="checked">
              <Checkbox onChange={(e) => onChangeExtraFields('oda', e.target.checked)} />
            </Form.Item>
          </Col>

          {extraFields?.currencies && (
            <Col span={24} xl={6}>
              <Form.Item
                name="currencies"
                label="Valute"
                rules={[
                  {
                    required: extraFields?.currencies,
                    message: 'Tipul de valută este necesar!',
                  },
                ]}
              >
                <Select mode="tags" allowClear placeholder="Tipul de valută" />
              </Form.Item>
            </Col>
          )}

          {extraFields?.oda && (
            <Col span={24} xl={6}>
              <Form.Item
                name="oda_insurance_rates"
                label="Rate de asigurare ODA"
                rules={[
                  {
                    required: extraFields?.oda,
                    message: 'Rata de asigurare ODA este necesară!',
                  },
                ]}
              >
                <Select mode="tags" allowClear placeholder="Rate de asigurare ODA" />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row justify="end">
          <Col>
            <Button type="primary" htmlType="submit" loading={loading}>
              Salvează
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreditForm;
