import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Upload, Tabs, Row, Col, Radio, message } from 'antd';
import env from '@beam-australia/react-env';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { ArticleEditor } from 'components/Articles';
import api from 'lib/api';
import { getBase64 } from 'utils';
import { Properties } from 'types';

import { Link, LinkSelect } from '../Custom';
import { formItemStyle } from '../helpers';

const REACT_APP_API_BASE_URL = env('API_BASE_URL');
const maxImageSizeInMb = 4;

export default ({ item, lang, setFieldsValue }: Properties) => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!activeKey || Object.keys(item.tabs || []).length < parseInt(activeKey) + 1) {
      setActiveKey('0');
    }
  }, []);

  const handleChange = (info: Properties) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
  };

  const handleUpload = async (file: any): Promise<void> => {
    setLoading(true);
    try {
      const data = await api.pages.uploadFile(file);
      const { id: uid, upload, uploaded } = data;

      const newFile: UploadFile<any> = {
        uid,
        size: 1,
        name: upload,
        status: uploaded ? 'done' : 'error',
        url: `${REACT_APP_API_BASE_URL}/v1${upload}`,
        type: 'image',
      };

      setFieldsValue({
        [item.id]: {
          [lang]: {
            image: { fileList: [newFile], url: newFile.url },
          },
        },
      });
    } catch (e) {
      message.error('Unsuccessfull image upload!');
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file: any): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Puteți încărca doar imagini în formatul JPG/PNG!');
      return false;
    }

    const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
    if (!isMbLt) {
      message.error(`Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`);
      return false;
    }
    handleUpload(file);

    return false;
  };

  const onChangeTabs = useCallback(
    (key, action) => {
      switch (action) {
        case 'add':
          setFieldsValue({
            [item.id]: {
              [lang]: {
                tabs: [
                  ...(item?.tabs || []),
                  {
                    title: `Tarife (Oferta #${(item?.tabs?.length || 0) + 1})`,
                    rate: 0,
                    fee_rate: 0,
                    dae_rate: 11.08,
                    custom_rate: 0,
                    warning: '',
                  },
                ],
              },
            },
          });
          break;
        case 'remove':
          setFieldsValue({
            [item.id]: {
              [lang]: {
                tabs: (item?.tabs || []).filter((_, i) => i.toString() !== key),
              },
            },
          });
          break;
      }
    },
    [item, lang, setFieldsValue],
  );

  return (
    <>
      <Form.Item
        name={[item.id, lang, 'dataType']}
        initialValue="individual"
        label="Tip"
        style={formItemStyle}
      >
        <Radio.Group>
          <Radio value="individual">Persoane fizice</Radio>
          <Radio value="business">Business</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Imagine" name={[item.id, lang, 'image']} style={formItemStyle}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
        >
          {item?.image?.url ? (
            <img src={`${item.image.url}`} alt="avatar" style={{ width: '100%' }} />
          ) : (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Încărca</div>
            </div>
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        label="Titlu"
        name={[item.id, lang, 'title']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți titlul`} />
      </Form.Item>
      <Form.Item
        label="Subtitlu"
        name={[item.id, lang, 'subtitle']}
        rules={[
          {
            required: true,
            message: 'Subtitlu este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți subtitrare`} />
      </Form.Item>
      <Form.Item
        label="Termenul"
        name={[item.id, lang, 'term']}
        rules={[
          {
            required: true,
            message: 'Termenul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input type="number" placeholder="Introduceți termenul" min={0} suffix="luni" />
      </Form.Item>
      <Form.Item
        label="Plafon"
        name={[item.id, lang, 'ceiling']}
        rules={[
          {
            required: true,
            message: 'Plafonul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder="Introduceți plafonul" min={0} suffix="MDL" />
      </Form.Item>
      {item.dataType !== 'business' ? (
        <Tabs
          type="editable-card"
          addIcon={
            <Row gutter={[8, 8]}>
              <Col>
                <PlusOutlined />
              </Col>
              <Col>Adaugă oferta</Col>
            </Row>
          }
          activeKey={activeKey}
          onChange={setActiveKey}
          onEdit={onChangeTabs}
        >
          {item.tabs?.map((tabItem: Properties, i: number) => (
            <Tabs.TabPane forceRender key={i.toString()} tab={tabItem.title} closable>
              <Form.Item
                label="Titlul tabului"
                name={[item.id, lang, 'tabs', i, 'title']}
                initialValue={tabItem.title}
                rules={[
                  {
                    required: true,
                    message: 'Titlul tabului este necesar',
                  },
                ]}
                style={formItemStyle}
              >
                <Input placeholder={`Introduceți titlul tabului`} />
              </Form.Item>
              <Form.Item
                label="Rata dobânzii"
                name={[item.id, lang, 'tabs', i, 'rate']}
                initialValue={tabItem.rate}
                rules={[
                  {
                    required: true,
                    message: 'Rata este necesar',
                  },
                ]}
                style={formItemStyle}
              >
                <Input placeholder={`Introduceți rata`} min={0} max={100} suffix="%" />
              </Form.Item>
              <Form.Item
                label="Comision de administrare"
                name={[item.id, lang, 'tabs', i, 'fee_rate']}
                initialValue={tabItem.fee_rate}
                rules={[
                  {
                    required: true,
                    message: 'Comision de administrare este necesar',
                  },
                ]}
                style={formItemStyle}
              >
                <Input
                  placeholder={`Introduceți comision de administrare`}
                  min={0}
                  max={100}
                  suffix="%"
                />
              </Form.Item>
              <Form.Item
                label="DAE"
                name={[item.id, lang, 'tabs', i, 'dae_rate']}
                initialValue={tabItem.dae_rate}
                rules={[
                  {
                    required: true,
                    message: 'DAE este necesar',
                  },
                ]}
                style={formItemStyle}
              >
                <Input placeholder={`Introduceți DAE`} min={0} max={100} suffix="%" />
              </Form.Item>
              <Form.Item
                label="Alte comisioane"
                name={[item.id, lang, 'tabs', i, 'custom_rate']}
                initialValue={tabItem.custom_rate}
                style={formItemStyle}
              >
                <Input placeholder={`Introduceți alte comisioane`} min={0} max={100} suffix="%" />
              </Form.Item>
              <Form.Item
                label="Avertisment"
                name={[item.id, lang, 'tabs', i, 'warning']}
                initialValue={tabItem.warning}
                style={formItemStyle}
              >
                <Input placeholder={`Introduceți avertisment`} />
              </Form.Item>

              <Form.Item
                label="Detalii"
                name={[item.id, lang, 'tabs', i, 'details']}
                initialValue={tabItem.details}
                style={formItemStyle}
              >
                <ArticleEditor height={60} />
              </Form.Item>
              <Form.Item
                label="Detalii link"
                name={[item.id, lang, 'tabs', i, 'details_link']}
                initialValue={tabItem.details_link}
                style={formItemStyle}
              >
                <LinkSelect lang={lang} />
              </Form.Item>

              <Form.Item
                name={[item.id, lang, 'tabs', i, 'action']}
                initialValue={tabItem?.action}
                label="Acțiune"
                style={formItemStyle}
              >
                <Link lang={lang} />
              </Form.Item>
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : (
        <>
          <Form.Item label="Avertisment" name={[item.id, lang, 'warning']} style={formItemStyle}>
            <Input placeholder={`Introduceți avertisment`} />
          </Form.Item>

          <Form.Item label="Detalii" name={[item.id, lang, 'details']} style={formItemStyle}>
            <ArticleEditor height={60} />
          </Form.Item>

          <Form.Item
            label="Detalii link"
            name={[item.id, lang, 'details_link']}
            style={formItemStyle}
          >
            <LinkSelect lang={lang} />
          </Form.Item>

          <Form.Item name={[item.id, lang, 'action']} label="Acțiune" style={formItemStyle}>
            <Link lang={lang} />
          </Form.Item>
        </>
      )}
    </>
  );
};
