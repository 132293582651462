import * as React from 'react';
import { Form, Row, Col, Input, Modal } from 'antd';

import classifiers, { NomenclaturesClassifiers } from 'lib/api/classifiers';
import { Properties } from 'types';

const formItemStyle = {
  marginBottom: 0,
  lineHeight: 'initial',
};

interface Props {
  initialValue?: NomenclaturesClassifiers;
  onSaved: () => void;
}

const CustomOptionsForm: React.FC<Props> = ({ onSaved, initialValue }) => {
  const handleSubmit = async (values: Properties): Promise<void> => {
    try {
      await classifiers.upsertClassifiers({
        ...(initialValue?.id && { id: initialValue?.id }),
        ...values,
      } as NomenclaturesClassifiers);
      onSaved();
    } catch (e) {
      const { message = 'Eroare. Formularul nu a putut fi salvat!' } = e;

      Modal.error({ content: message });
    }
  };

  return (
    <Form id="CustomOptionsForm" onFinish={handleSubmit}>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={24}>
          <Form.Item
            label="Code"
            name="code"
            initialValue={initialValue?.code}
            rules={[
              {
                required: true,
                message: 'Codul este necesar',
              },
            ]}
            style={formItemStyle}
          >
            <Input placeholder={'Introduceți codul de optioune'} disabled={!!initialValue?.code} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Titlu"
            name="title"
            initialValue={initialValue?.title}
            rules={[
              {
                required: true,
                message: 'Titlu este necesar',
              },
            ]}
            style={formItemStyle}
          >
            <Input placeholder={`Introduceți titlul de optiune`} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CustomOptionsForm;
